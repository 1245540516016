<template>
  <default-layout
    route-name="Descontos Concedidos"
    has-filters
    :unique-filter="false"
    :instructions="instructions"
    :loading="loading"
    @search="search"
  >
    <template #header-actions>
      <v-spacer />

      <generate-reports-button
        table="discounts"
        label="Gerar Relatório"
        filename="relatorio_descontos"
        :filter="filter"
      />

      <generate-reports-button
        table="discounts-cn"
        label="Gerar Relatório CN"
        filename="relatorio_descontos_cn"
        :filter="filter"
        color="primary"
      />
    </template>

    <template #content>
      <discount-table :items="items" :pagination="pagination" />
    </template>

    <template #footer>
      <pagination v-model="pagination" @change="loadData" :loading="loading" />
    </template>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/components/layout/DefaultLayout.vue";
import PaginationMixin from "@/components/pagination/PaginationMixin";
import FilterMixin from "@/components/Filters/FilterMixin";
import Pagination from "@/components/pagination/Pagination.vue";
import GenerateReportsButton from "@/components/Reports/GenerateReportsButton.vue";

import DiscountService from "@/app/Services/DiscountService";
import DiscountTable from "./components/DiscountTable.vue";

export default {
  components: {
    DefaultLayout,
    Pagination,
    GenerateReportsButton,
    DiscountTable
  },
  name: "Discount",
  data() {
    return {
      loading: false,
      items: [],
      instructions: {
        search_for: [
          { text: "Valor do Desconto", field: "value", type: "currency" },
          { text: "Fatura", field: "invoice" },
          { text: "Data de Concessão", field: "created_at", type: "date_range" }
        ],
        filter_for: [
          { text: "Apta para gerar CN", field: "is_cn", value: true }
        ]
      }
    };
  },
  mixins: [PaginationMixin, FilterMixin],
  methods: {
    async loadData() {
      try {
        const query = {
          params: {
            ...this.pagination,
            itemsPerPage: 10,
            filter: this.filter
          }
        };

        this.loading = true;

        const response = await DiscountService.build().index({ query });

        this.items = response.data.map(item => {
          item.loading = false;

          return item;
        });

        this.paginate(response);
      } catch (error) {
        console.error(error);
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
        this.$forceUpdate();
      }
    },
    getApiQuery() {
      return {
        params: {
          ...this.pagination,
          filter: { ...this.filter }
        }
      };
    }
  }
};
</script>

<style></style>
